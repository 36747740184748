<template>
  <b-carousel
    id="carousel-1"
    class="w-100 teste h-100"
    v-model="slide"
    :interval="4000"
    controls
    indicators
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <b-carousel-slide v-for="(image, index) in images" :key="index">
      <template #img>
        <a target="_blank" :href="image.link">
          <div
            :class="image.class"
            :style="{ backgroundImage: `url(${image.src}` }"
          ></div>
        </a>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },

  data() {
    return {
      slide: 0,
      sliding: null,

      images: [
        {
          class: "img-custom img-saude-mental",
          src: require("@/assets/images/login/saude-mental.svg"),
          link: "https://saudemental.sesisc.org.br/",
        },
        {
          class: "img-custom img-academia",
          src: require("@/assets/images/login/academia.svg"),
          link: "https://sesisc.org.br/pt-br/academias-sesi",
        },
        {
          class: "img-custom img-odonto",
          src: require("@/assets/images/login/odonto.svg"),
          link: "https://sesisc.org.br/pt-br/odontosesi",
        },

      ],
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
.img-custom {
  width: 100%;
  height: calc(100vh - 62px);
  background-repeat: no-repeat;
}

.img-saude-mental,
.img-academia,
.img-odonto {
  background-position: left;
  background-size: contain;
}

@media (max-height: 1720px) {
  .img-saude-mental {
    background-position: left;
    background-size: cover;
  }

  .img-academia {
    background-position: center;
    background-size: cover;
  }

  .img-odonto {
    background-position: 10% 0;
    background-size: cover;
  }
}
</style>
