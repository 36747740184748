<template>
  <b-row class="w-100 m-0">
    <b-col
        lg="12"
        class="d-flex justify-content-center"
    >
      <slot></slot>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
  },

  data() {
    return {}
  },
}
</script>