<template>
  <div class="row bg-white m-0 custom-base">
    <div class="col-carousel">
      <LoginCarousel />
    </div>

    <div class="col-form">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LoginCarousel from "@/views/components/carousel/LoginCarousel";

export default {
  components: {
    LoginCarousel,
  },

  data() {
    return {}
  },
}
</script>

<style>
  .col-carousel {
    width: 55%;
  }

  .img-custom{
    background-size: cover  ;
  }

  .col-form {
    width: 45%;
    height: calc(100vh - 62px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-base {
    width: 100%;
  }
  @media(min-height: 0px) and (max-height: 600px) {
    .col-form {
      width: 100%;
      height: auto;
    }

    .custom-base {
      justify-content: center;
      height: auto;
    }

    .col-carousel {
      display: none;
    }

  }

  @media (max-width: 1100px) {
    .custom-base {
      justify-content: center;
      height: auto;
    }

    .col-carousel {
      display: none;
    }

    .col-form {
      width: 100%;
    }
  }
</style>