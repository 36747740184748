<template>
  <div class="row nav-menu-auth">
    <nav class="menuLinksLogin fixed-top">
      <ul class="nav">
        <li v-for="menu in menus" class="nav-item">
          <a class="nav-link" :title="menu.title" :href="menu.href" :rel="menu.rel">{{ menu.name }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style>
  .nav-menu-auth {
    height: 62px;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  nav.menuLinksLogin{
    background: #002060;
    height: 62px;
  }
  nav.menuLinksLogin ul{
    margin-left: 158px;
    height: 62px;
    display: flex;
    align-items: center;
  }
  nav.menuLinksLogin ul li a{
    color: #ffffff;
    font-size: 15px
  }
  @media (max-width:1100px){
    nav.menuLinksLogin ul{
      margin-left: 0;
    }
    nav.menuLinksLogin{
      display: flex;
      justify-content: center;
    }
  }
</style>
<script>
export default{
  props:{
    menus:Array
  }
}

</script>
