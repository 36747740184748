<template>
  <div class="auth-wrapper auth-v2 auth-custom">
    <NavMenu :menus="validMenu" />

    <ClearBase v-if="clear">
      <slot></slot>
    </ClearBase>

    <DefaultBase v-else>
      <slot></slot>
    </DefaultBase>
  </div>
</template>

<script>
import NavMenu from "@/views/components/custom/nav/NavMenu";
import ClearBase from "@/views/pages/authentication/BaseAuth/ClearBase";
import DefaultBase from "@/views/pages/authentication/BaseAuth/DefaultBase";

export default {
  props: ['clear'],

  components: {
    NavMenu,
    ClearBase,
    DefaultBase,
  },

  data() {
    return {
      menu: [
        {
          href:"https://fiesc.com.br/",
          name:"FIESC",
          title:"Federação das Indústrias do Estado de Santa Catarina",
          rel:"Federação das Indústrias do Estado de Santa Catarina"
        },
        {
          href:"https://ciesc.com.br/",
          name:"CIESC",
          title:"O CIESC é uma entidade do Sistema FIESC",
          rel:"O CIESC é uma entidade do Sistema FIESC"
        },
        {
          href:"https://portaldaindustria.com.br/sesi",
          name:"SESI",
          title:"O Serviço Social da Indústria",
          rel:"O Serviço Social da Indústria"
        },
        {
          href:"https://portaldaindustria.com.br/senai",
          name:"SENAI",
          title:"Serviço Nacional de Aprendizagem Industrial",
          rel:"Serviço Nacional de Aprendizagem Industrial"
        },
        {
          href:"https://portaldaindustria.com.br/iel",
          name:"IEL",
          title:"O Instituto Euvaldo Lodi",
          rel:"O Instituto Euvaldo Lodi"
        }
      ],
    }
  },
  computed: {
    validMenu(){
      return this.validEmptyAttr(this.menu);
    },
  },
  methods: {
    validEmptyAttr(data){
      return data.filter(function(v){
        for(let value in v){
          if(!v[value]){
            return false;
          }
        }
        return true;

      });
    },
  },
}
</script>

<style>
  .auth-custom {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
</style>
